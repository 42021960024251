<template lang="pug">
.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module.rounded-xl(
  style='grid-template-rows: auto auto auto auto 1fr'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("objects.create.new.group") : $t("objects.create.edit.group") }}
  .flex.justify-end.col-start-2.items-center
    img.mr-5(
      src='../../assets/icons/history.svg',
      style='width: 18px',
      v-if='notRole && title !== 1',
      @click='$emit("show-history", { objects: "unitsgroup", objectId: group.id })'
    )
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .col-start-1.mb-5.flex.justify-end.col-end-3.col-end-4.mt-6
    skif-button.mr-6(variant='normal', @click='$emit("close")') {{ $t("cancel") }}
    skif-button(v-if='!admin', @click='save("unitGroup")') {{ $t("btn.save") }}
  .flex.font-semibold.text-sm.font-SourceSansPro.pt-4.pb-6.col-start-1.col-end-4
    .pr-3.cursor-pointer.transition-colors.duration-300(
      @click='activeTab = "units"',
      :class='activeTab === "units" ? "text-darkblue" : "text-annotationColor"'
    ) Объекты
    .px-3.cursor-pointer.transition-colors.duration-300(
      v-if='!admin',
      @click='activeTab = "access"',
      :class='activeTab === "access" ? "text-darkblue" : "text-annotationColor"'
    ) Доступ
  .col-start-1.col-end-4.border-b.border-dividerColor.pb-8
    .flex.flex-col.relative
      p.text-annotationColor.text-base.font-SourceSansPro.font-semibold {{ $t("objects.name.group") }}
      el-input(
        type='text',
        class='w-1/2',
        v-model='group.name',
        :class='{ "input-error": isNameMissing }'
      )
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='isNameMissing') {{ $t("objects.name") }}
  .col-start-1.col-end-4.overflow-y-auto.mt-2
    table.table-fixed.w-full(v-if='activeTab === "units"')
      thead.text-left
        tr
          th.bg-white.top-0.sticky
            span.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("objects.objects") }}
          th.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllCheckedUnits',
              :indeterminate='isIndeterminateUnits',
              :disabled='!filterDataUnits.length'
            )
        tr.border-b.border-reallylightblue
          th.sticky.bg-white.z-10(style='top: 32px', colspan='2')
            skif-search.w-64.my-2(
              v-model='filterStringUnits'
              :placeholder='$t("search")'
              class='w-1/2'
              @searching="searching"
            )
      tbody
        tr.border-b.border-reallylightblue(v-for='unit in filterDataUnits')
          td.truncate
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUnits.includes(unit.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ unit.name }}
          td.w-10
            skif-checkbox(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedUnits',
              :label='unit.id',
              :showLabel='false'
            )
    table.w-full(v-else)
      thead.text-left
        tr
          th.bg-white.top-0.sticky
            span.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("objects.users") }}
          th.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllCheckedUsers',
              :indeterminate='isIndeterminateUsers',
              :disabled='!filterDataUsers.length'
            )
        tr.border-b.border-reallylightblue
          th.sticky.bg-white.z-10(style='top: 32px', colspan='2')
            skif-search.w-64.my-2(
              v-model='filterStringUsers'
              :placeholder='$t("search")'
              class='w-1/2'
              @searching="searchingUser"
            )
      tbody
        tr.border-b.border-reallylightblue(v-for='user in filterDataUsers')
          td.truncate.max-w-xs
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUsers.includes(user.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ user.name }}
          td.w-10
            skif-checkbox(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedUsers',
              :label='user.id',
              :showLabel='false'
            )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    title: Number,
    group: {
      type: Object,
      default: () => {
        return {
          name: '',
          units: [],
          users: [],
        }
      },
    },
    admin: Boolean,
  },
  data() {
    return {
      sortedUnits: [],
      sortedUsers: [],
      activeTab: 'units',
      isDeleting: false,
      filterStringUnits: '',
      checkedUnits: [],
      filterStringUsers: '',
      checkedUsers: [],
      isNameMissing: false,
    }
  },
  created() {
    this.$store.dispatch('users/GET')

    this.checkedUnits = this.group.units.map((k) => k.id)
    this.units.sort((a, b) => (this.checkedUnits.includes(b.id) ? 1 : -1))
    this.checkedUsers = this.group.users ? this.group.users.map((k) => k.id) : []
    this.users.sort((a, b) => (this.checkedUsers.includes(b.id) ? 1 : -1))
  },

  watch: {
    'group.name': function (val) {
      this.isNameMissing = val === ''
    },
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    isIndeterminateUnits() {
      return (
        this.checkedUnits.length > 0 &&
        this.checkedUnits.length < this.filterDataUnits.length
      )
    },
    isAllCheckedUnits: {
      get() {
        return (
          this.checkedUnits.length >= this.filterDataUnits.length &&
          this.filterDataUnits.length
        )
      },
      set(value) {
        this.checkedUnits = value ? this.unitIds : []
      },
    },
    unitIds() {
      return this.filterDataUnits.map((k) => k.id)
    },
    filterDataUnits() {
      return this.units.filter((unit) =>
        JSON.stringify(unit)
          .toLowerCase()
          .includes(this.filterStringUnits.toLowerCase())
      )
    },
    ...mapState('units', {
      units: (state) => state.defaultUnits,
    }),
    filterDataUsers() {
      return this.users.filter((user) =>
        JSON.stringify(user)
          .toLowerCase()
          .includes(this.filterStringUsers.toLowerCase())
      )
    },
    ...mapState('users', {
      users: (state) => state.users,
    }),
    userIds() {
      return this.filterDataUsers.map((k) => k.id)
    },
    isIndeterminateUsers() {
      return (
        this.checkedUsers.length > 0 &&
        this.checkedUsers.length < this.filterDataUsers.length
      )
    },
    isAllCheckedUsers: {
      get() {
        return (
          this.checkedUsers.length >= this.filterDataUsers.length &&
          this.filterDataUsers.length
        )
      },
      set(value) {
        this.checkedUsers = value ? this.userIds : []
      },
    },
  },
  methods: {
    searching(val) {
      this.filterStringUnits = val
    },
    searchingUser(val) {
      this.filterStringUsers = val
    },
    valid() {
      let error = true
      if (this.group.name === '') {
        this.isNameMissing = !this.group.name
        error = false
      }
      return error
    },
    save() {
      if (this.valid()) {
        console.log(this.group, 'units');
        this.group.units = this.checkedUnits.map((id) => {
          return {
            id: id,
          }
        })
        // eslint-disable-next-line vue/no-mutating-props
        this.group.users = this.checkedUsers.map((id) => {
          return {
            id: id,
          }
        })
        this.title === 1
          ? this.$store
              .dispatch('units/CREATE_UNITGROUP', this.group)
              .then((_) => this.$emit('close'))
          : this.$store
              .dispatch('units/UPDATE_UNITGROUP', this.group)
              .then((_) => this.$emit('close'))
      }
    },
  },
}
</script>
